import { CustomValidators } from '../app/core/helpers/validators/validators.helper';

export const isGe = false;
export const isUz = true;
export const phoneNumber = '(71) 205 12 22';

export const defaultLanguage = 'uz';
export const languages = ['uz', 'en', 'ru'];

export const currencyLabel = 'UZS';
export const currencyFormatDigits = 2;
export const currencyFormat = `1.${currencyFormatDigits}-${currencyFormatDigits}`;
export const currencyFormatDigitsShort = 0;
export const currencyFormatShort = `1.${currencyFormatDigitsShort}-${currencyFormatDigitsShort}`;

export const phoneNumberMinLength = 9;
export const phoneNumberMaxLength = 9;
export const phoneNumberValidator = CustomValidators.LocalPhoneUzNumber;
export const packagesRoute = 'dokonni-avtomatlashtirish-paketlar';
export const MENUTREE = [
  {
    title: 'MainNavigation.Home',
    url: '/',
    target: '_self',
  },
  {
    title: 'MainNavigation.Packages',
    url: `/${packagesRoute}`,
  },
];
